import Image from "@atoms/Image";
import Text from "@atoms/Text";
import {appDownloadLink, mobileDeviceTypes} from "@helpers/constants";
import texts from '@molecules/Footer/V2/en.json';
import {Button} from "@ui/atoms/design-system";
import React, {useEffect, useState} from "react";
import DownloadAppQrCode from "@molecules/Footer/V2/DownloadAppQrCode";
import {getMobileOS} from "@helpers/utils";
import { triggerCustomEvent } from "@helpers/miscelleanous";

const AppLinks = ({isLoggedIn = false, className}) => {

    const [showDownloadAppQrCode, setShowDownloadAppQrCode] = useState(null);
    const [isIos, setIsIos] = useState(null);
    const [mobileOs, setMobileOs] = useState(null);
    const onDownloadClick = ({ show, isIos }) => {
        if (show) {
            triggerCustomEvent(isIos ? 'ViewedIosQrFooter' : 'ViewedAndroidQrFooter');
        }
        setIsIos(isIos);
        setShowDownloadAppQrCode(show);
    };

    useEffect(() => {
        setMobileOs(getMobileOS());
    }, []);

    const getMobileDownloadButton = () => {
        return (
            <div
                className={`md:hidden`}>
                <a
                    href={appDownloadLink} target='_blank'
                    id={`gtm-click-app-link-${mobileOs}`}
                    className='cursor-pointer'>
                    {mobileOs === mobileDeviceTypes.ios ?
                        <Image
                            src={'/images/footer/app-store-badge.webp'}
                            alt="app-store"
                            className={`h-9`}/>
                        : mobileOs === mobileDeviceTypes.android ?
                            <Image
                                src={'/images/footer/google-play-badge.webp'}
                                alt="play-store"
                                loading='lazy'
                                className={`h-9`}/>
                            : <Button
                                prefixComponent={
                                    <Image src='/images/footer/download_app.svg' alt="download"/>
                                }
                                buttonType="special"
                                buttonSize='extraSmall'
                                buttonText={texts?.Download}
                            />}
                </a>
            </div>
        );
    }

    return (
        <div className={`flex flex-row gap-y-3 gap-x-6 items-center ${className}`}>
            <Text content={texts?.DownloadOfficialApp}
                  className={`p5-medium text-basicWhite ${!isLoggedIn ? 'hidden' : 'hidden md:block'}`}/>
            {/*for mobile */}
            <Text
                className={`p5-medium text-basicWhite ${!isLoggedIn ? 'hidden' : 'md:hidden'}`}
                content={
                    <>{texts?.DownloadOfficialAppMobile1}
                        <br/>
                        {texts?.DownloadOfficialAppMobile2}
                    </>
                }/>
            {/*for web/tab*/}
            <div className={`hidden md:flex items-center shrink-0 gap-x-4`}>
                <Button
                    prefixComponent={
                        <Image src='/images/footer/app_store.svg' alt="app-store"/>
                    }
                    buttonType="special"
                    buttonSize={isLoggedIn ? 'extraSmall' : 'regular'}
                    buttonText={texts?.GetIosApp}
                    onClick={() =>
                        onDownloadClick({
                            show: true,
                            isIos: true,
                        })
                    }
                />
                <Button
                    prefixComponent={
                        <Image src='/images/footer/google_play.svg' alt="google-play"/>
                    }
                    buttonType="special"
                    buttonSize={isLoggedIn ? 'extraSmall' : 'regular'}
                    buttonText={texts?.GetAndroidApp}
                    onClick={() =>
                        onDownloadClick({
                            show: true,
                            isIos: false,
                        })
                    }
                />
                {showDownloadAppQrCode && (
                    <DownloadAppQrCode
                        show={showDownloadAppQrCode}
                        isIos={isIos}
                        onClose={() =>
                            onDownloadClick({
                                qrImage: null,
                                isIos: null,
                            })
                        }
                    />
                )}
            </div>

            {/*for mobile */}
            {getMobileDownloadButton()}
        </div>
    );
};

export default AppLinks;