import {
    twitterUrl,
    facebookUrl,
    linkedInUrl,
    instagramUrl,
    youtubeUrl,
} from '@helpers/constants';
import { isNewsLetterPageActive } from '@helpers/constants';
import { getAuth } from '@services/identity.service';

const auth = getAuth();

export const footersLinks = [
    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: 'Terms of Use', link: '/terms-and-condition' },
    { name: 'Disclaimer', link: '/disclaimer' },
];

export const socialMediaLinks = [
    {
        name: 'linkedin',
        image: '/images/SocialMediaIcons/linkedin.svg',
        url: linkedInUrl,
    },
    {
        name: 'youtube',
        image: '/images/SocialMediaIcons/youtube.svg',
        url: youtubeUrl,
    },
    {
        name: 'instagram',
        image: '/images/SocialMediaIcons/instagram.svg',
        url: instagramUrl,
    },
    {
        name: 'x',
        image: '/images/SocialMediaIcons/X.svg',
        url: twitterUrl,
    },
    {
        name: 'facebook',
        image: '/images/SocialMediaIcons/facebook.svg',
        url: facebookUrl,
    },
];

export const footerData = [
    {
        title: 'About us',
        link: '/about-us',
        onlyLogin: false,
        subMenu: [
            {
                title: 'Our Company',
                link: '/about-us#',
                onlyLogin: false,
            },
            {
                title: 'The Journey',
                link: '/about-us#milestones',
                onlyLogin: false,
            },
            {
                title: 'Values',
                link: '/about-us#values',
                onlyLogin: false,
            },
            {
                title: 'Investors',
                link: '/about-us#investors',
                onlyLogin: false,
            },
            // Hide this for now
            // {
            //     title: 'The Team',
            //     link: '/team',
            //     onlyLogin: false,
            // },
            {
                title: 'Careers',
                link: '/careers',
                onlyLogin: false,
            },
            {
                title: 'Contact Us',
                link: '/contact-us',
                onlyLogin: false,
            },
        ],
    },
    {
        title: 'Products',
        link: '/products',
        onlyLogin: false,
        subMenu: [
            {
                title: 'Corporate Debt (Unlisted)',
                link: '/products/unlisted-corporate-debt',
                onlyLogin: false,
            },
            {
                title: 'Venture Debt',
                link: '/products/venture-debt',
                onlyLogin: false,
            },
            {
                title: 'Invoice Discounting',
                link: '/products/invoice-discounting',
                onlyLogin: false,
            },
            {
                title: 'Asset Backed Leasing',
                link: '/products/asset-backed-leasing',
                onlyLogin: false,
            },
        ],
    },
    {
        title: 'Knowledge Centre',
        link: null,
        onlyLogin: false,
        subMenu: [
            {
                title: 'Credit Assessment Process',
                link: '/credit-evaluation-process',
                onlyLogin: false,
            },
            {
                title: 'Blog',
                link: '/blogs',
                onlyLogin: false,
            },
            // Hide these for now 
            // {
            //     title: 'Media',
            //     link: '/media',
            //     onlyLogin: false,
            // },
            {
                title: 'Webinars',
                link: '/webinars',
                onlyLogin: false,
            },
            {
                title: 'Newsletter',
                link: '/news-letters',
                onlyLogin: false,
                show: isNewsLetterPageActive
            },
            {
                title: 'FAQs',
                link: '/faq',
                onlyLogin: false,
            },
        ],
    },
    {
        title: 'Quick Links',
        link: null,
        onlyLogin: false,
        subMenu: [
            {
                title: 'Opportunities',
                link: auth ? '/opportunities' : '/signup',
                onlyLogin: false,
            },
            {
                title: 'Risk Disclosure',
                link: '/risk-disclosure',
                onlyLogin: false,
            },
            {
                title: 'Refer & Earn',
                link: '/referandearn',
                onlyLogin: false,
            },
            {
                title: 'Become a Partner',
                target: '_blank',
                link: `${process.env.NEXT_PUBLIC_APP_PARTNER_URL}`,
                onlyLogin: false,
            },
        ],
    },
];
