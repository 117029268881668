import { ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { Avatar, Image, LazyImage, Link, Text } from '@atoms/index';
import { useRouter } from 'next/router';
import useNameAvatar from 'hooks/useNameAvatar';
import { Button } from '@ui/atoms/design-system';
import {getAuth} from "@services/identity.service";
import texts from "@components/Investor/en.json";
import {capitalizeWords, replaceKeysWithValues} from "@helpers/string";
import { triggerMenuGaEvents } from '@helpers/miscelleanous';

const HamburgerMenuItem = ({
    menuItem,
    isUser,
    logout,
    closeMenu,
    showRMDetails,
    rmDetails,
    isLoggedIn,
}) => {
    const { name, submenu, link, target } = menuItem;
    const { avatar } = useNameAvatar(name);
    const router = useRouter();
    const auth = getAuth();

    const renderRMSubMenu = (rmDetails) => {
        return (
            <>
                <div className="">
                    <div
                        className={`flex h-10 justify-around items-center mt-4 text-primary-500 `}
                    >
                        <button
                            className={`hover:bg-gray-200 flex-1 h-full bg-gray-100 rounded-md font-semibold text-base`}
                            onClick={async () => {
                                await router.push('/profile');
                                closeMenu();
                            }}
                            id="myProileButton"
                        >
                            <Image
                                className={`inline h-5 pr-3`}
                                src={`/images/UserProfile.svg`}
                            />
                            Profile
                        </button>
                        <div className={`h-4 w-0.5 mx-3 bg-gray-300`} />
                        <button
                            className={`hover:bg-gray-200 flex-1 h-full rounded-md font-semibold text-base`}
                            onClick={logout}
                            id="gtm-logout-button"
                        >
                            <Image
                                className={`inline h-5 pr-3`}
                                src={`/images/Logout.svg`}
                            />
                            Logout
                        </button>
                    </div>
                    <div className="h-0.5 mt-4 w-full bg-gray-100" />
                    <Text
                        className={`py-4 text-gray-400 font-medium text-xs text-left`}
                        content="Your Relationship Manager"
                    />
                    <div className="flex items-center justify-start">
                        <Image
                            src={`${
                                rmDetails?.image
                                    ? rmDetails?.image
                                    : '/images/user-solid.svg'
                            }`}
                            className={`w-10 h-10  ${
                                !rmDetails?.image &&
                                'border-2 border-gray-400 bg-gray-400'
                            } rounded-full`}
                        />
                        <Text
                            className={`pl-4 text-gray-900 font-semibold text-base`}
                            content={rmDetails?.name}
                        />
                    </div>
                    <div className="mt-4 text-left ">
                        <Text
                            className={`pr-6 text-gray-900 font-semibold text-sm inline`}
                            content="Email"
                        />
                        <a href={`mailto:${rmDetails?.email}`}>
                            <Text
                                className={`hover:text-primary-500 hover:font-medium hover:-ml-px text-gray-900 text-sm inline`}
                                content={rmDetails?.email}
                            />
                        </a>
                    </div>
                    <div className="my-2 text-left ">
                        <Text
                            className={`text-gray-900 font-semibold text-sm inline`}
                            content="Phone"
                        />
                        <Text
                            className={`pl-4 text-gray-900 text-sm inline`}
                            content={rmDetails?.mobile}
                        />
                    </div>
                    <a
                        href={`https://wa.me/${rmDetails?.mobile}?text=${encodeURIComponent(
                            replaceKeysWithValues(texts?.RMWhatsappMessage,
                                {
                                    '{name}':capitalizeWords(auth?.name),
                                    '{mobile}':auth?.mobile,
                                }))}`}
                        target="_blank"
                        className="w-full"
                    >
                        <Button
                            id="chatWithRM"
                            buttonSize="small"
                            widthClass={`w-full`}
                            prefixComponent={<Image src={`/images/Chat.svg`} />}
                            buttonText={`Chat with RM`}
                        />
                    </a>
                    <div className="flex justify-start mt-5 text-left ">
                        <Image
                            className="mt-0.5 h-3 w-3"
                            src={`/images/InfoDark.svg`}
                        />
                        <Text
                            className={`pl-2.5 text-gray-500 text-xs`}
                            content={`Your relationship manager will be available on Whatsapp chat between 10:30 AM-7:30 PM, Monday to Friday.`}
                        />
                    </div>
                </div>
            </>
        );
    };

    if (submenu) {
        const [isOpen, setIsOpen] = useState(false);
        return (
            <div>
                <div
                    className={`border-b-[1px] border-gray-200 flex justify-between py-5 text-primary-500 hover:text-primary-300 gap-x-2 ${
                        isUser ? 'bg-basicWhite px-2' : ''
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isUser && avatar ? (
                        <div className="flex ml-1 max-w-[80%] gap-1 items-center">
                            <div className="w-8 h-8">
                                <Avatar icon={avatar} />
                            </div>
                            <Text
                                content={name}
                                className="ml-1 text-sm font-semibold"
                            />
                        </div>
                    ) : isUser ? (
                        <div className="inline-block ">
                            <div className="w-8 h-8 p-2 rounded-full bg-primary-30">
                                <LazyImage
                                    src="/images/UserProfile.svg"
                                    className="relative w-full h-full"
                                    alt="user"
                                />
                            </div>
                        </div>
                    ) : (
                        <Text
                            content={name}
                            className="text-base font-semibold"
                        />
                    )}
                    <ChevronDownIcon
                        className={`w-5 translate-all duration-100 ${
                            isOpen ? 'rotate-180' : ''
                        }`}
                    />
                </div>
                <ul>
                    {isOpen &&
                        (showRMDetails
                            ? renderRMSubMenu(rmDetails)
                            : submenu
                                  ?.filter((each) =>
                                      isLoggedIn ? each.showWhenLoggedIn : each
                                  )
                                  ?.filter((item) => !(item.show === false))
                                  ?.map((item) => (
                                      <li className={`p-3`}>
                                          <Link href={item.link} onClick={() => triggerMenuGaEvents(item.name)}>
                                              <div
                                                  className={`text-base ${
                                                      item.link
                                                          ? 'text-gray-500 hover:text-primary-500'
                                                          : 'text-gray-300'
                                                  } font-semibold`}
                                                  onClick={() => {
                                                      closeMenu();

                                                      if (
                                                          item.name.toLowerCase() ===
                                                              'logout' &&
                                                          isUser &&
                                                          logout
                                                      ) {
                                                          logout();
                                                      }
                                                  }}
                                                  id={item.id}
                                              >
                                                  <Image
                                                      className={`inline h-5 pr-3`}
                                                      src={item.icon}
                                                  />
                                                  {item.name}
                                              </div>
                                          </Link>
                                      </li>
                                  )))}
                </ul>
            </div>
        );
    }
    return (
        <Link
            href={link}
            target={target}
            className={`border-b-[1px] relative border-border-200 flex justify-start items-center py-5`}
            onClick={closeMenu}
        >
            <Text
                content={name}
                className="text-base font-semibold text-primary-500"
                id={menuItem.id}
            />
        </Link>
    );
};
export default HamburgerMenuItem;
