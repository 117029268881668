import { capitalizeWords, replaceKeysWithValues } from '@helpers/string';
import { getAuth } from '@services/identity.service';
import { Image, Text } from '@ui/atoms';
import { Button } from '@ui/atoms/design-system';
import investorsTexts from "@components/Investor/en.json";
import texts from "./en.json";
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import { triggerCustomEvent } from '@helpers/miscelleanous';

const RmElement = ({
    rmDetails,
    className = 'p-3 border-t border-gray-100 mt-3 flex flex-col gap-2 w-full',
    whatsAppIcon = "/images/whatsapp.svg",
    buttonType = "secondary",
    gTag
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const auth = getAuth();  

  const copyText = (text) => {
    setIsCopied(true);
    window.navigator.clipboard.writeText(text);
    setTimeout(() => {
        setIsCopied(false);
    }, 2000)
 };  
    
  return (
      <div className={className}>
          <div className="flex gap-4 items-center">
              <Image src={rmDetails?.image} className="w-8 h-8 rounded-full" />
              <Text
                  content={rmDetails?.name}
                  className="p5-medium text-basicBlack"
              />
          </div>
          {rmDetails?.email ? (
              <div className="flex gap-4 items-center">
                  <Text
                      content={'Email'}
                      className="p6-regular text-basicBlack"
                  />
                  <Text
                      content={rmDetails?.email}
                      className="p6-underline text-primary-500"
                  />
              </div>
          ) : null}

          {rmDetails?.mobile ? (
              <div className="flex gap-[10px] items-center">
                  <Text
                      content={'Phone'}
                      className="p6-regular text-basicBlack"
                  />
                  <div className="flex items-center gap-1">
                      <Text
                          content={rmDetails?.mobile}
                          className="p6-medium text-basicBlack"
                      />
                      {isCopied ? (
                          <CheckCircleIcon className="text-secondary-500 w-4 h-4" />
                      ) : (
                          <button onClick={() => copyText(rmDetails?.mobile)}>
                              <Image
                                  src="/images/copy-secondary.svg"
                                  className="w-4 h-4"
                              />
                          </button>
                      )}
                  </div>
              </div>
          ) : null}

          {rmDetails?.mobile ? (
              <a
                  href={`https://wa.me/${
                      rmDetails?.mobile
                  }?text=${encodeURIComponent(
                      replaceKeysWithValues(investorsTexts?.RMWhatsappMessage, {
                          '{name}': capitalizeWords(auth?.name),
                          '{mobile}': auth?.mobile,
                      })
                  )}`}
                  onClick={() => triggerCustomEvent(gTag)}
                  target="_blank"
                  className="w-full"
              >
                  <Button
                      buttonText={texts?.ChatWithUs}
                      buttonType={buttonType}
                      widthClass="w-full"
                      className="mt-1"
                      buttonSize="extraSmall"
                      prefixComponent={
                          <Image src={whatsAppIcon} className="w-4 h-4" />
                      }
                  />
              </a>
          ) : null}
          <Text
              content={texts?.availableRmTiming}
              className="p6-regular text-gray-500 text-center"
          />
      </div>
  );
}

export default RmElement;