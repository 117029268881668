import React, { Fragment } from 'react';
import { Link } from '@ui/atoms';
import ExpandNavigationItem from './ExpandNavigationItem';
import { triggerCustomEvent } from '@helpers/miscelleanous';

const MenuItemsWithAuth = ({ menuItems, showChildren, setShowChildren, handleMenu }) => (
    <div className="flex flex-col gap-1">
        {menuItems.map((item, index) => (
            <Fragment key={index}>
                <ExpandNavigationItem
                    icon={item?.icon}
                    link={item?.link || null}
                    field={{
                        name: item?.name,
                        class: 'p5-medium text-gray-500',
                    }}
                    handleMenu={handleMenu}
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    gTag={item?.gTagMobileLoggedIn}
                >
                    {item?.submenu?.length > 0 ? (
                        <div className="px-3 pb-3 pt-3 w-full">
                            <hr className="border-t border-t-gray-200 w-full pt-3" />
                            <div className="flex flex-col items-start justify-start gap-4 w-full">
                                {item?.submenu
                                    ?.filter((item) => !(item.show === false))
                                    ?.map((subItem, subIndex) => (
                                        <Link
                                            key={subIndex}
                                            href={subItem?.link}
                                            className="p5-medium text-primary-500"
                                            onClick={() => {
                                                handleMenu();
                                                if (subItem?.gTagMobileLoggedIn)
                                                    triggerCustomEvent(
                                                        subItem?.gTagMobileLoggedIn
                                                    );
                                            }}
                                        >
                                            {subItem?.name}
                                        </Link>
                                    ))}
                            </div>
                        </div>
                    ) : null}
                </ExpandNavigationItem>
            </Fragment>
        ))}
    </div>
);

export default MenuItemsWithAuth;